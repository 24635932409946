<template>
    <div>
        <img src="https://seetop-1257860468.file.myqcloud.com/vue/img/square-banner.jpg" class="banner">
        <van-list
                v-model="listLoading"
                :finished="listLoadingFinished"
                finished-text="没有更多了"
                @load="onLoad"
                v-if="listLoadingList.length > 0"
        >
            <div class="comment-wrapper" v-for="(item, index) in listLoadingList" :key="index">
                <div class="comment-user-wrapper">
                    <div class="comment-user-info-wrapper">
                        <img :src="item.headimgurl" class="comment-user-head">
                        <div class="comment-user-info-base">
                            <div class="comment-user-name">{{item.weixinNick}}</div>
                            <van-rate v-model="item.stars" :size="size" readonly/>
                        </div>
                    </div>
                    <div class="comment-time-wrapper">{{item.comment_time}}</div>
                </div>
                <div class="comment-text-wrapper">{{item.comment}}</div>
                <div class="comment-img-wrapper">
                    <img :src="'https://seetop-1257860468.file.myqcloud.com/seetop/uploads/' + item2" class="comment-img" v-for="item2,index2 in item.comment_img" :key="index2" @click="preView(index, index2)">
                </div>
                <product-small-size-item class="product-item" :name="item.productName" :salePrice="item.mallprice" :orgPrice="item.price" :img="item.trademark" :cellId="item.product_cell_id"></product-small-size-item>
                <div class="comment-zan-wrapper">
                    <div class="comment-zan-info-wrapper" style="margin-right: 2rem;">
                        <!--未点赞-->
                        <img v-if="item.opinion !== 'support'" src="https://seetop-1257860468.file.myqcloud.com/vue/img/%E7%82%B9%E8%B5%9E.png" class="comment-icon" @click="opinion(item.id,'support',index)">
                        <!--已点赞-->
                        <img v-if="item.opinion === 'support'" src="https://seetop-1257860468.file.myqcloud.com/vue/img/%E7%82%B9%E8%B5%9E%E5%B7%B2%E7%82%B9%E5%87%BB.png" class="comment-icon">
                        <div class="comment-zan-number">{{item.support}}</div>
                    </div>
                    <div class="comment-zan-info-wrapper">
                        <!--未踩-->
                        <img v-if="item.opinion !== 'oppose'" src="https://seetop-1257860468.file.myqcloud.com/vue/img/%E8%B8%A9.png" class="comment-icon" @click="opinion(item.id,'oppose',index)">
                        <!--已踩-->
                        <img v-if="item.opinion === 'oppose'" src="https://seetop-1257860468.file.myqcloud.com/vue/img/%E8%B8%A9%E5%B7%B2%E7%82%B9%E5%87%BB.png" class="comment-icon">
                        <div class="comment-zan-number">{{item.oppose}}</div>
                    </div>
                </div>
            </div>
        </van-list>
        <!--前往评论-->
        <div class="write-comment" @click="writeComment">
            <img src="https://seetop-1257860468.file.myqcloud.com/vue/img/%E5%86%99%E8%AF%84%E4%BB%B7%402x.png">
        </div>
        <nav-bar></nav-bar>
    </div>
</template>

<script>
  import ProductSmallSizeItem from '../../components/product/ProductSmallSizeItem'
  import { globalConfig, listLoading } from '../../utils/mixin'
  import { ImagePreview } from 'vant'
  import NavBar from '../../components/common/NavBar'
  export default {
    name: 'Square',
    components: { NavBar, ProductSmallSizeItem },
    mixins: [listLoading, globalConfig],
    data  () {
      return {
        size: 13
      }
    },
    methods: {
      opinion: function (id, type, index) {
        const that = this
        //  避免无用的请求
        if (that.listLoadingList[index].opinion) {
          return
        }
        //  点赞
        this.axios.post(this.apiUrl + 'mall/comment/commentOpinion', {
          id: id,
          type: type
        }).then((response) => {
          const data = response.data
          console.log(data)
          if (data.status === 1) {
            //  不刷新页面情况下刷新数据
            that.listLoadingList[index].opinion = type
            if (type === 'support') {
              that.listLoadingList[index].support++
            } else if (type === 'oppose') {
              that.listLoadingList[index].oppose++
            }
          } else {
            this.$toast(data.msg)
          }
        })
      },
      writeComment: function () {
        this.$router.push('/commentList')
      },
      preView: function (index1, index2) {
        const preViewArr = this.listLoadingList[index1].comment_img.map(function (value, index, array) {
          return 'https://seetop-1257860468.file.myqcloud.com/seetop/uploads/' + value
        })
        ImagePreview({
          images: preViewArr,
          startPosition: index2
        })
      }
    },
    mounted () {
      this.listLoadingInit(this.apiUrl + 'mall/comment/getCommentList', 'list', 1, {})
    }
  }
</script>

<style scoped>
    .banner {
        width: 100%;
        box-shadow: 0 0 0.4rem #b1b1b1;
    }
    .comment-wrapper {
        width: 100%;
        padding: 1rem;
        box-sizing: border-box;
        border-bottom: 0.1rem solid #f0f0f0;
        margin-bottom: 2rem;
        background-color: #ffffff;
    }
    .comment-user-wrapper {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        width: 98%;
        margin: 0 auto;
    }
    .comment-user-info-wrapper {
        display: flex;
        flex-flow: row;
        align-items: center;
    }
    .comment-user-info-base {
        display: flex;
        flex-flow: column;
        justify-content: space-around;
        height: 3rem;
        margin-left: 0.5rem;
    }
    .comment-user-name {
        color: #62606e;
        font-size: 0.9rem;
    }
    .comment-user-head {
        width: 3rem;
        height: 3rem;
        border-radius: 10rem;
    }
    .comment-zan-wrapper {
        display: flex;
        flex-flow: row;
        align-items: flex-end;
        justify-content: flex-end;
        margin-top: 1rem;
    }
    .comment-zan-info-wrapper {
        display: flex;
        flex-flow: row;
    }
    .comment-icon {
        width: 1.2rem;
        height: 1rem;
    }
    .comment-zan-number {
        font-size: 0.9rem;
        color: #999999;
        margin-left: 0.3rem;
    }
    .comment-text-wrapper {
        margin: 1rem auto 0;
        color: #999999;
        font-size: 0.9rem;
        width: 97%;
    }
    .comment-img-wrapper {}
    .comment-img {
        height: 5rem;
        width: 5rem;
        margin: 1rem 1rem 0 0;
    }
    .comment-time-wrapper {
        color: #999999;
        font-size: 0.8rem;
        /*margin: 1rem 0 0.5rem 0;*/
        letter-spacing: 0.1rem;
        height: 3rem;
        box-sizing: border-box;
        padding-top: 0.4rem;
    }
    .product-item {
        margin-top: 1rem;
    }
    .write-comment {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;
        width: 3.5rem;
        height: 3.5rem;
        position: fixed;
        right: 1rem;
        bottom: 5rem;
        border-radius: 10rem;
        background: #ffffff;
        box-shadow: 0 0 0.6rem #d2d2d2;
    }
    .write-comment img {
        width: 2rem;
        height: 2rem;
    }
</style>
