<!--导航栏-->
<template>
    <div>
        <div class="space"></div>
        <div class="footer-nav">
            <router-link to="/home" :class="[currentPath == '/home' ? 'footer-active' : 'footer-default','footer-nav-item']">
                <img class="footer-img" :src="currentPath == '/home' ? 'https://seetop-1257860468.file.myqcloud.com/vue/img/page/nav/%E9%A6%96%E9%A1%B5%E9%80%89%E4%B8%AD1207.png' : 'https://seetop-1257860468.file.myqcloud.com/vue/img/page/nav/%E9%A6%96%E9%A1%B5%E6%9C%AA%E9%80%89%E4%B8%AD1207.png'">
                <div>首页</div>
            </router-link >
            <router-link to="/search" :class="[currentPath == '/search' ? 'footer-active' : 'footer-default','footer-nav-item']">
                <img class="footer-img" :src="currentPath == '/search' ? 'https://seetop-1257860468.file.myqcloud.com/vue/img/page/nav/%E6%90%9C%E7%B4%A2%E9%80%89%E4%B8%AD1207.png' : 'https://seetop-1257860468.file.myqcloud.com/vue/img/page/nav/%E6%90%9C%E7%B4%A2%E6%9C%AA%E9%80%89%E4%B8%AD1207.png'">
                <div>找餐</div>
            </router-link>
<!--            <router-link to="/square" :class="[currentPath == '/square' ? 'footer-active' : 'footer-default','footer-nav-item']">-->
<!--                <img class="footer-img" :src="currentPath == '/square' ? 'https://seetop-1257860468.file.myqcloud.com/vue/img/20210601-评论选中.png' : 'https://seetop-1257860468.file.myqcloud.com/vue/img/20210601-评论未选中.png'">-->
<!--                <div>点评</div>-->
<!--            </router-link>-->
            <div class="footer-default footer-nav-item" @click="showCustomerServicePop">
                <img class="footer-img" src="https://seetop-1257860468.file.myqcloud.com/vue/img/page/nav/%E5%95%86%E5%AE%B6%E5%85%A5%E9%A9%BB%E6%9C%AA%E9%80%89%E4%B8%AD1207.png">
                 <div style="font-size: 0.8rem;">商家入驻</div>
            </div>
            <router-link to="/user" ref="userPath" :class="[currentPath == '/user' ? 'footer-active' : 'footer-default','footer-nav-item']">
                <img class="footer-img" :src="currentPath == '/user' ? 'https://seetop-1257860468.file.myqcloud.com/vue/img/page/nav/%E6%88%91%E7%9A%84%E9%80%89%E4%B8%AD1207.png' : 'https://seetop-1257860468.file.myqcloud.com/vue/img/page/nav/%E6%88%91%E7%9A%84%E6%9C%AA%E9%80%89%E4%B8%AD1207.png'">
                <div>我的</div>
            </router-link>
        </div>
        <customer-service ref="customerService"></customer-service>
    </div>
</template>

<script>
  import CustomerService from './CustomerService'
  export default {
    name: 'NavBar',
    components: { CustomerService },
    data () {
      return {
        currentPath: ''
      }
    },
    methods: {
      // 展示客服面板
      showCustomerServicePop: function () {
        this.$refs.customerService.showPop()
      }
    },
    mounted () {
      this.currentPath = this.$route.path
    }
  }
</script>

<style scoped>
    .footer-nav{
        display: flex;
        flex-flow: row;
        z-index: 99;
        background-color: #ffffff;
        position: fixed;
        left: 0;
        bottom:0;
        width: 100%;
        align-items: center;
        border-top: 0.03rem solid #d1d1d1;
        color: #535353;
        padding: 0.5rem 0;
    }
    .footer-nav-item {
        width: 25%;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
    }
    .footer-nav-item div {
        /*font-size: 0.9rem;*/
        margin-top: 0.2rem;
    }
    .footer-default{
        color: #535353;
    }
    /*.footer-nav > a > i{*/
    /*    font-size: 1.5rem;*/
    /*}*/
    .footer-nav > a > div{
        font-size: 0.8rem;
    }
    .footer-active {
        color: #D62422;
    }
    .space {
        width: 100%;
        height: 5rem;
    }
    .footer-img {
        width: 1.6rem;
        min-height: 1.5rem;
    }
</style>
