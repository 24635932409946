<!--商品展示，小图，在评论广场用到-->
<template>
    <div class="product-wrapper">
        <img :src="'https://seetop-1257860468.file.myqcloud.com/seetop/uploads/' + img" class="product-img">
        <div class="product-info-wrapper">
            <div class="product-name">{{name}}</div>
            <div class="product-info-bottom-wrapper">
                <div class="product-price-wrapper">
                    <div>￥{{moneyFen2Yuan(salePrice)}}</div>
                    <div>￥{{moneyFen2Yuan(orgPrice)}}</div>
                </div>
                <div class="product-button" @click="toDetail">马上抢</div>
            </div>
        </div>
    </div>
</template>

<script>
  import { moneyFen2Yuan } from '../../utils/tools'

  export default {
    name: 'ProductSmallSizeItem',
    props: ['img', 'name', 'salePrice', 'orgPrice', 'cellId'],
    methods: {
      moneyFen2Yuan,
      toDetail: function () {
        this.$router.push('/product/' + this.cellId)
      }
    }
  }
</script>

<style scoped>
    .product-wrapper {
        width: 97%;
        margin: 0 auto;
        /*box-shadow: 0 0 0.6rem 0.05rem #dadada;*/
        box-sizing: border-box;
        padding: 1rem;
        display: flex;
        flex-flow: row;
        border-radius: 0.4rem;
        background-color: #F7F7F7;
    }
    .product-img {
        width: 4rem;
        height: 4rem;
    }
    .product-info-wrapper {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        margin-left: 1rem;
    }
    .product-name {
        font-size: 0.9rem;
        overflow:hidden;
        text-overflow:ellipsis;
        display:-webkit-box;
        -webkit-box-orient:vertical;
        -webkit-line-clamp:2;
        min-width: 15rem;
    }
    .product-info-bottom-wrapper {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        margin-top: 0.5rem;
    }
    .product-price-wrapper {
        display: flex;
        flex-flow: row;
        align-items: flex-end;
    }
    .product-price-wrapper div:first-child {
        font-size: 1.1rem;
        color: #fa1f1f;
        font-weight: 600;
    }
    .product-price-wrapper div:nth-child(2) {
        font-size: 0.9rem;
        color: #999999;
        margin-left: 0.3rem;
    }
    .product-button {
        background-color: #fa1f1f;
        color: #ffffff;
        border-radius: 10rem;
        font-size: 0.9rem;
        padding: 0.1rem 1rem;
        text-align: center;
    }
</style>
